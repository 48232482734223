<template>
    <v-container fluid>
        <v-snackbar
            v-model="snackbar"
            app
            top
            elevation="24"
            :timeout="2000">
            <v-icon color="primary">
                mdi-check
            </v-icon>
            Link Copied to Clipboard!
        </v-snackbar>
        <v-row>
            <v-col>
                <h1 class="font-weight-regular">
                    Browser Miner FAQs
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p>
                    This section highlights features and information about the Browser Miners.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                    tile
                    max-width="600px">
                    <v-subheader>
                        Quick Links
                    </v-subheader>
                    <v-list dense>
                        <v-list-item-group>
                            <v-list-item
                                v-for="(item, key) in anchorLinks"
                                :key="key">
                                <router-link :to="'/browser-miner-faqs#'+item.target">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title">
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </router-link>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-for="(item, key) in anchorLinks"
            :key="key"
            :id="item.target">
            <v-col cols="12">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <h3 v-text="item.title">
                        </h3>
                    </v-col>
                    <v-col>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    icon
                                    @click="copyUrl(item.target)">
                                    <v-icon>
                                        mdi-link-variant
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Copy Link to this Section</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-card tile>
                    <v-card-text v-html="item.content">
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Ad from './../components/ads/GeneralAd';

    export default {
        metaInfo: {
            titleTemplate: 'Browser Miner FAQs - %s'
        },
        data() {
            return {
                snackbar: false,
                selectedItem: null,
                anchorLinks: [
                    {
                        title: 'I have a Gold membership but my account does not reflect this?',
                        target: 'what_is_browser_miner',
                        content: '<p>Since this app is still somewhat decoupled from the main website your subscription needs to be syn\'d anytime it changes.</p><p>We do this automatically for you when you login but in the event that your already logged in we have placed a sync icon in the account dropdown menu.  This menu is located in the top right and can be accessed by clicking the three vertical dots.</p><p class="mb-0">If for some reason this does not work please contact us so we can look into the issue for you.</p>'
                    },
                    {
                        title: 'What is the Pool+ Browser Miner?',
                        target: 'what_is_browser_miner',
                        content: '<p class="mb-0">The Pool+ Browser Miner was designed as a tool to allow you to mine cryptocurrency coins.  The miner uses computer processing power to generate hashes that get sent to a pool.  Once a hash is accepted you get credited for the work with shares.  Once the pool finds a block your shares get turned into the coin that you are mining.</p>'
                    },
                    {
                        title: 'Can I run multiple devices at the same time?',
                        target: 'multiple_miners',
                        content: '<p class="mb-0">Yes, you can virtually open and run the miner on any devices that have a web browser and an internet connection.</p>'
                    },
                    {
                        title: 'How much can I make?',
                        target: 'how_much',
                        content: '<p class="mb-0">How much you can make depends on your hash rate, the pool and luck.  Since the coin price, network difficulty and block reward are always changing it can be hard to figure an exact number.  We’re currently working on an earnings calculator that will give you an estimate of what you should earn based upon a hash rate.</p>'
                    },
                    {
                        title: 'How do I start mining on the Browser Miners?',
                        target: 'how_to_start_mining',
                        content: '<p class="mb-0">To start mining on the Browser Miners you have to register an account and obtain an active membership.  Once you have your membership you need to go to your wallet page and add your wallet address, this step is very important.  After that navigate to the miner, select your coin, select your pool, and click the “Start” button.  Once you see the hash chart update with your hashes you are mining!</p>'
                    },
                    {
                        title: 'When do I get paid?',
                        target: 'when_are_payouts',
                        content: '<p class="mb-0">You get paid out based upon what the pool sets the min payout amount.  A pool’s min payout amount can vary from pool to pool.  You can view the pool’s min payout under the “Pool Stats” section on the miner page.  All pools on the miners connect directly to the wallet address specified in your wallets page.</p>'
                    },
                    {
                        title: 'Can I change my wallet address after I started mining?',
                        target: 'change_wallet_address',
                        content: '<p class="mb-0">You can change your wallet address after you start mining but the pools use your wallet address to associate earning and shares to an account.  Once you start mining with a wallet address any coins and shares earned will be under that wallet address.  This is on the pool side of things and not handled by the Browser Miners.</p>'
                    },
                    {
                        title: 'Can I mine directly to an exchange?',
                        target: 'mining_on_exchange',
                        content: '<p class="mb-0">Some pools allow you to mine directly to an exchange like Poloniex.  As of right now, we do not determine if a pool allows this so it’s best to use the wallets suggested here.</p>'
                    },
                    {
                        title: 'How do I cash out the coins I’ve earned?',
                        target: 'cashing_out',
                        content: '<p class="mb-0">There are a couple of ways to cash out your coins that you have earned.  You can send your coins to an exchange like Poloniex, sell them for USDT, LTC or BTC and then transfer it to Coinbase.  Once in Coinbase, you can send to your PayPal or bank account.  Keep in mind that usually there are fees involved when transfer digital currency.</p>'
                    },
                    {
                        title: 'What if the website logs me out?',
                        target: 'logged_out',
                        content: '<p class="mb-0">If the website logs you out while the miner is running it will still continue to run.  Unless the “Stop” button is clicked or page tab/browser is closed the miner will continue to run in the background.</p>'
                    },
                    {
                        title: 'My pool data is not showing up?',
                        target: 'missing_pool_data',
                        content: '<p class="mb-0">If your pool data does not show up then there could be a lost connection between the pool’s api and our miner.  If this is the case check back later to see if the pool data is showing up.  If the problem persists you can submit a support ticket.</p>'
                    },
                    {
                        title: 'The miner stopped working?',
                        target: 'miner_stopped',
                        content: '<p class="mb-0">If the miner has stopped producing hashes try refreshing the page to see if it starts working.  If it does not you can submit a support ticket.</p>'
                    },
                    {
                        title: 'How do I create/upgrade to a Gold Membership?',
                        target: 'account_upgrade',
                        content: '<p class="mb-0">If your an existing user or your new you can create/upgrade your account by clicking here.</p>'
                    },
                    {
                        title: 'How do I cancel my membership?',
                        target: 'account_cancel',
                        content: '<p class="mb-0">To cancel your membership you can click here.</p>'
                    }
                ]
            }
        },
        components: {
            Ad
        },
        methods: {
            copyUrl(target) {
                let url = window.location.origin+this.$router.resolve({name: 'faqs'}).route.path+'#'+target
                let tempInput = document.createElement("input")
                tempInput.value = url
                document.body.appendChild(tempInput)
                tempInput.select()
                document.execCommand("copy")
                document.body.removeChild(tempInput)
                this.snackbar = true;
            }
        }
    }
</script>